import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { setupListeners } from '@reduxjs/toolkit/query';
import { router } from '@routes';
import { store } from '@store';

import AuthProvider from '@features/auth/AuthProvider';
import MaintenanceProvider from '@features/maintenance/MaintenanceProvider';
import ThemeCustomization from '@themes/index';

import 'react-toastify/dist/ReactToastify.css';

setupListeners(store.dispatch);

function App() {
  return (
    <ReduxProvider store={store}>
      <ThemeCustomization>
        <MaintenanceProvider>
          <AuthProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <RouterProvider
                future={{ v7_startTransition: true }}
                router={router}
              />
              <ToastContainer autoClose={5000} />
            </LocalizationProvider>
          </AuthProvider>
        </MaintenanceProvider>
      </ThemeCustomization>
    </ReduxProvider>
  );
}

export default App;
